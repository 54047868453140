<template>
  <div class="filters-panel">
    <div class="field" v-if="this.teachers.length">
      <v-select
        label="nickname"
        :options="teachers"
        v-model="selectedTeacher"
        :reduce="item => item.id"
        autocomplete="on"
        clearable
        placeholder="Select teacher"
      >
        <template #option="{ nickname, options }">
          <vs-avatar
            :src="
              options && options.avatar
                ? `${proxy}/static/files/${options.avatar}`
                : undefined
            "
            size="small"
          ></vs-avatar
          >{{ nickname }}
        </template>
        <template #selected-option="{ nickname, options }">
          <vs-avatar
            :src="
              options && options.avatar
                ? `${proxy}/static/files/${options.avatar}`
                : undefined
            "
            size="small"
          ></vs-avatar>
          {{ nickname }}
        </template>
      </v-select>
    </div>
    <div class="field">
      <v-select
        label="name"
        :options="groups"
        v-model="selectedGroups"
        :reduce="item => item.id"
        autocomplete="on"
        clearable
        multiple
        placeholder="Select groups"
        :closeOnSelect="false"
      >
        <template #option="{ color, name }">
          <vs-avatar icon="people" :color="color"></vs-avatar>
          {{ name }}
        </template>
        <template #selected-option="{ color, name }">
          <vs-avatar icon="people" :color="color" size="20px"></vs-avatar>
          {{ name }}
        </template>
      </v-select>
    </div>
    <div class="field">
      <v-select
        label="filterClass"
        :options="groupClasses"
        v-model="selectedClasses"
        :reduce="item => item.filterClass"
        autocomplete="on"
        clearable
        multiple
        placeholder="Select group class"
        :closeOnSelect="false"
      >
        <template #option="{ viewClass }">
          {{ viewClass }}
        </template>
        <template #selected-option="{ viewClass }">
          {{ viewClass }}
        </template>
      </v-select>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import vSelect from 'vue-select'

export default {
  props: ['teacher_id'],

  data() {
    let { teacher_id } = this
    if (typeof teacher_id == 'string') teacher_id = parseInt(teacher_id, 10)
    return {
      selectedTeacher: teacher_id,
      selectedGroups: [],
      selectedClasses: [],
      proxy: process.env.VUE_APP_PROXY,
    }
  },
  components: {
    'v-select': vSelect,
  },
  computed: {
    ...mapState({
      currentYear: state => state.years.currentSchoolYears,
      groups: state => state.groups.groups,
      teachers: state => state.teachers.teachers,
    }),
    groupClasses: function() {
      if (!this.groups.length) return []

      let classes = this.groups.reduce((output, group) => {
        let groupClass = ''
        const regex1 = RegExp('([A-Za-z0-9 ])*(?=.|/d/)', 'g')
        // const regex1 = RegExp('[A-Za-z0-9 ]*', 'g')
        groupClass = regex1.exec(group.name)
        if (!Object.keys(output).includes(groupClass[0])) {
          let viewClass = groupClass[0]
          let filterClass = groupClass[0]
          if (group.name.includes('.')) {
            filterClass += '.'
          }
          output[viewClass] = {
            filterClass,
            viewClass,
          }
        }
        return output
      }, {})
      return Object.values(classes)
    },
  },
  watch: {
    selectedTeacher: function() {
      this.updateFilters()
    },
    selectedGroups: function() {
      this.updateFilters()
    },
    selectedClasses: function() {
      this.updateFilters()
    },
  },
  created: async function() {
    const schoolyear_id = window.localStorage.getItem('schoolyearId')
    if (!this.groups.length) {
      await this.$store.dispatch('groups/getGroupsAction', {
        data: {
          schoolyear_id,
        },
      })
    }
    if (!this.teachers.length) {
      await this.$store.dispatch('teachers/getTeachersAction')
    }
  },
  methods: {
    updateFilters: function() {
      const { selectedTeacher, selectedGroups, selectedClasses } = this
      this.$emit('filterTimetableEvent', {
        teachers: selectedTeacher,
        groups: selectedGroups,
        classes: selectedClasses,
      })
    },
  },
}
</script>

<style lang="scss">
.filters-panel {
  display: flex;
  flex-flow: row nowrap;
  gap: 15px;
  & > * {
    margin-right: 10px;
  }
  .field {
    margin: 0;
    min-width: 15rem;
  }
  .vs__actions {
    .vs__clear {
      display: block !important;
    }
  }
}
</style>
