<template>
  <div class="new-timetable">
    <div class="manage">
      <div class="manage-add-tutor__button" v-if="$can('manage', 'lesson')">
        <vs-button type="flat" icon="add" @click="addLessonHandler('testing')"
          >Add testing</vs-button
        >
      </div>
      <div class="manage-filters">
        <vs-icon icon="filter_list"></vs-icon>
        <filters
          :teacher_id="teacher_id"
          @filterTimetableEvent="filterHandler"
        />
      </div>
      <div class="manage-timetable">
        <vs-button
          type="flat"
          color="dark"
          icon="chevron_left"
          @click="changeView(-1)"
        ></vs-button>
        <vs-button type="flat" color="dark" @click="goToday" title="Today"
          >|</vs-button
        >
        <vs-button
          type="flat"
          color="dark"
          icon="chevron_right"
          @click="changeView(1)"
        ></vs-button>
      </div>
    </div>
    <table class="main-table">
      <tbody>
        <tr class="table-header">
          <td>
            <div class="wrapper">
              <table>
                <colgroup>
                  <col style="width: 4%" />
                  <col style="width: 16%" />
                  <col style="width: 16%" />
                  <col style="width: 16%" />
                  <col style="width: 16%" />
                  <col style="width: 16%" />
                  <col style="width: 10.66%" />
                  <col style="width: 5.33%" />
                </colgroup>
                <tbody>
                  <tr class="table-days">
                    <td class="header-cell border"></td>
                    <td
                      class="header-cell border"
                      v-for="(item, key) in week"
                      :key="key"
                    >
                      <div class="inner">
                        <div>
                          <span class="weekday">{{ item.weekDay }}</span> /
                          <span class="day">{{ item.dayText }}</span>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr class="table-rooms">
                    <td class="header-cell border"></td>
                    <td
                      class="header-cell border"
                      v-for="(item, key) in week"
                      :key="key"
                    >
                      <div class="rooms-inner">
                        <div
                          class="room"
                          v-for="room in item.rooms"
                          :key="room.id"
                        >
                          <vs-icon
                            :icon="room.icon"
                            :title="room.name"
                            size="18px"
                          ></vs-icon>
                          <!-- <span>{{ room.name }}</span> -->
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </td>
        </tr>
        <tr class="table-body">
          <td>
            <div class="wrapper body-wrapper">
              <div class="table-slots">
                <table>
                  <colgroup>
                    <col style="width: 4%" class="hour-slot" />
                  </colgroup>
                  <colgroup>
                    <col style="width: 96%" />
                  </colgroup>
                  <tbody>
                    <tr
                      v-for="(h, index) in hours"
                      :key="index"
                      class="hour-row"
                    >
                      <td>
                        <div class="hour-cell">
                          <span v-if="!(index % 2)">{{ h }}:00</span>
                        </div>
                      </td>
                      <td class="hour-cell"></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="table-cels">
                <table>
                  <colgroup>
                    <col style="width: 4%" />
                  </colgroup>
                  <colgroup></colgroup>
                  <tbody>
                    <tr>
                      <td></td>
                      <td
                        class="event-cell"
                        v-for="(col, index) in events"
                        :key="index"
                        :class="{
                          last:
                            events[index + 1] &&
                            col.day != events[index + 1].day,
                        }"
                      >
                        <div class="event-cell-wrap">
                          <router-link
                            :to="{
                              name: 'Lessons',
                              params: { lesson_id: l.id },
                            }"
                            target="_blank"
                            class="event"
                            :style="{
                              top: l.topPosition + 'px',
                              height: l.height + 'px',
                              backgroundColor: l.backgroundColor + '99',
                            }"
                            :class="{
                              cancelled: l.extendedProps.cancelled,
                              testing: l.lesson_type == 'testing',
                            }"
                            @mouseover.native="eventOver($event)"
                            @mouseleave.native="eventLeave($event)"
                            v-for="l in col.lessons"
                            :key="l.id"
                            :title="getLessonTitle(l)"
                            :id="`event-${l.id}`"
                            :data-custom-id="l.title"
                          >
                            <div class="title">
                              {{ l.title }}
                            </div>
                            <div class="teachers">
                              <div class="teachers__main">
                                {{ l.extendedProps.main_teacher }}
                              </div>
                              <div
                                class="teachers__other"
                                v-for="(other, id) in l.extendedProps
                                  .other_teachers"
                                :key="id"
                              >
                                {{ other.nickname }}
                              </div>
                            </div>
                            <div class="done">
                              <vs-icon
                                v-if="l.extendedProps.done"
                                icon="done"
                              ></vs-icon>
                            </div>
                            <div
                              class="is_online"
                              v-if="l.extendedProps.is_online"
                              title="Online"
                            >
                              <vs-icon icon="online_prediction"></vs-icon>
                            </div>
                          </router-link>
                        </div>
                        <div class="event-cell-now-indicator">
                          <div
                            class="indicator"
                            v-if="nowIndicator.day == col.day"
                            :style="{ top: nowIndicator.topPosition + 'px' }"
                          ></div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <vs-popup
      class="popup__lessons"
      classContent=""
      :title="`Add ${lessonType} lesson`"
      :active.sync="popupActive"
      @close="closePopupHandle"
    >
      <LessonForm
        v-if="popupActive"
        :startDate="new Date()"
        :lessonDuration="tutorLessonDuration"
        :lessonType="lessonType"
        :submitDisabled="lessonFormSubmitDisabled"
        @saveLesson="saveLessonHandler"
      />
    </vs-popup>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import LessonForm from '@/components/lessons/generalLessonForm'
import Filters from './filters.vue'

export default {
  props: ['teacher_id'],
  data() {
    return {
      //   startDate: new Date(1631327722 * 1000),
      startDate: this.$moment(),
      week: {},
      events: [],
      editable: this.$can('manage', 'lessons'),
      hoursInterval: [8, 19.5],
      minDate: undefined,
      maxDate: undefined,
      popupActive: false,
      filters: {},
      nowIndicator: {
        time: new Date(),
      },
      dayFormat: 'YY.DD.MM',
      lessonType: 'tutor',
      lessonFormSubmitDisabled: false,
    }
  },
  computed: {
    ...mapState({
      teachers: state => state.teachers.teachers,
      lessons: state => state.lessons.lessons,
      rooms: state => state.rooms.rooms,
    }),
    hours: function() {
      let hours = []
      let [start, end] = [...this.hoursInterval]
      for (var i = start; i <= end; i = i + 0.5) {
        hours.push(i)
      }
      return hours
    },
    size: function() {
      const slotsBlock = document.querySelector('.table-slots')
      if (slotsBlock) {
        return [slotsBlock.offsetWidth, slotsBlock.offsetHeight]
      }
      return [0, 0]
    },
    minuteHeight: function() {
      return this.size[1] / this.hours.length / 30
    },
  },
  components: {
    LessonForm,
    Filters,
  },
  methods: {
    getWeek: function() {
      if (this.startDate.day() == 0) this.startDate.add(1, 'day')
      this.minDate = this.$moment(this.startDate).startOf('isoweek')
      this.maxDate = this.$moment(this.startDate).endOf('isoweek')

      let days = {}
      for (var i = 0; i <= 6; i++) {
        const day = this.$moment(this.minDate).add(i, 'days')
        // берем кабинеты для субботы
        let rooms = []
        if (i == 5) {
          // суббота
          rooms = this.rooms.filter(
            r => !r.name.includes('5') && !r.name.includes('6')
          )
        } else if (i == 6) {
          // воскресенье
          rooms = this.rooms.filter(
            r => r.name.includes('1') || r.name.includes('2')
          )
        } else {
          rooms = [...this.rooms]
        }

        days[day.format(this.dayFormat)] = {
          day,
          weekDay: day.format('dddd'),
          dayText: day.format('DD.MM'),
          rooms,
        }
      }
      this.week = days
    },
    getItems: async function() {
      const { groups, teachers, classes } = this.filters
      const filterData = {
        start: this.minDate.unix(),
        end: this.maxDate.unix(),
      }
      if (groups && groups.length) filterData.group_id = groups
      if (teachers) filterData.teacher_id = teachers
      if (classes) filterData.classes = classes

      await this.$store.dispatch('lessons/getLessonsAction', filterData)
      let { minuteHeight } = this

      const week = { ...this.week }

      for (var day in week) {
        let d = week[day]
        d.rooms = d.rooms.map(r => {
          return {
            ...r,
            lessons: [],
            day,
            roomsLength: d.rooms.length,
          }
        })
        week[day] = d
      }
      this.lessons.forEach(lesson => {
        const {
          id,
          backgroundColor,
          title,
          start,
          extendedProps,
          lesson_type,
        } = lesson
        const lessonTime = this.$moment(start * 1000)
        const day = week[lessonTime.format(this.dayFormat)]
        if (!day) return false // если не нашли день недели, то пропускаем
        const room = day.rooms.find(r => r.id == lesson.room_id)
        if (!room) {
          console.error(
            `room ${lesson.room_id} in day ${day.dayText} not found`
          )
          return false
        }
        const topPosition = Math.floor(
          ((lessonTime.hours() - this.hoursInterval[0]) * 60 +
            lessonTime.minutes()) *
            minuteHeight
        )
        const height = Math.floor(extendedProps.duration * minuteHeight)
        room.lessons.push({
          id,
          title,
          lesson_type,
          backgroundColor,
          topPosition,
          height,
          extendedProps,
        })

        // const lessonTime = $$moment
      })
      this.events = Object.values(week).reduce((a, w) => {
        return [...a, ...w.rooms]
      }, [])
    },
    closePopupHandle: function() {
      this.popupActive = false
    },
    saveLessonHandler: function(data) {
      // неправильно, но так проще
      this.lessonFormSubmitDisabled = true
      data = {
        ...data,
        lesson_time: data.date,
        name: data.lessonName,
        teacher_id: data.teacher,
        room_id: data.room,
        students: data.student.length ? data.student : [data.student], // если просто число, то это один ученик
      }
      this.$store
        .dispatch('lessons/saveTutorLessonAction', { data })
        .then(async () => {
          this.popupActive = false
          await this.getItems()
          this.lessonFormSubmitDisabled = false
        })
        .catch(e => {
          this.$vs.notify({
            title: 'Error',
            text: `${e}`,
            color: 'danger',
            position: 'top-right',
          })
          this.lessonFormSubmitDisabled = false
        })
    },
    filterHandler: async function(filters) {
      this.filters = filters
      await this.getItems()
    },
    changeView: async function(direction) {
      this.startDate.add(direction, 'weeks')
      this.getWeek()
      await this.getItems()
    },
    goToday: async function() {
      this.startDate = this.$moment()
      this.getWeek()
      await this.getItems()
    },
    getNowIndicator: function() {
      const now = this.$moment()
      this.nowIndicator = {
        day: now.format(this.dayFormat),
        topPosition: Math.floor(
          ((now.hours() - this.hoursInterval[0]) * 60 + now.minutes()) *
            this.minuteHeight
        ),
      }
      // выделяем занятия, которые сейчас идут
      this.lessons.forEach(l => {
        const start = this.$moment(l.start * 1000)
        const end = this.$moment(l.start * 1000).add(
          l.extendedProps.duration,
          'minutes'
        )
        const el = document.querySelector(`#event-${l.id}`)
        if (el) {
          if (now >= start && now <= end) {
            el.classList.add('in-progress')
            el.style.backgroundColor = l.backgroundColor
          } else {
            el.classList.remove('in-progress')
            el.style.backgroundColor = l.backgroundColor + '99'
          }
        }
      })
    },
    getLessonTitle: function(l) {
      const {
        main_teacher,
        other_teachers,
        assistants,
        students,
        cancelled,
        cancel_description,
      } = l.extendedProps
      let output = ''

      if (cancelled) {
        output += 'Lesson cancelled'
        if (cancel_description) {
          output += ': ' + cancel_description
        }
        output += '\n'
        output += '\n'
      }

      output += `Main teacher: ${main_teacher}`

      if (Object.keys(other_teachers).length) {
        output += '\n'
        output += `Other teachers: ${Object.values(other_teachers)
          .map(o => o.nickname)
          .join(', ')}`
      }
      if (Object.keys(assistants).length) {
        output += '\n'
        output += `Assistants: ${Object.values(assistants)
          .map(o => o.nickname)
          .join(', ')}`
      }

      if (students) {
        output += '\n'
        output += '\n'
        output += `${students.join('\n')}`
      }
      // return l.extendedProps.students
      //   ? l.extendedProps.students.join("\n")
      //   : "";

      return output
    },
    addLessonHandler(lessonType) {
      this.lessonType = lessonType
      this.popupActive = true
    },
    eventOver: function(event) {
      const el = event.target.closest('.event')
      const customId = el.dataset.customId
      const events = document.querySelectorAll(
        `.event[data-custom-id="${customId}"]`
      )
      events.forEach(e => {
        e.classList.add('active')
      })
    },
    eventLeave: function(event) {
      const el = event.target.closest('.event')
      const customId = el.dataset.customId
      const events = document.querySelectorAll(
        `.event[data-custom-id="${customId}"]`
      )
      events.forEach(e => {
        e.classList.remove('active')
      })
    },
  },
  created: async function() {
    if (this.teacher_id) {
      this.filters.teachers = parseInt(this.teacher_id)
    }
    await this.$store.dispatch('rooms/getRoomsAction')
    this.getWeek()
    setInterval(() => {
      this.getNowIndicator()
    }, 60 * 2 * 1000) // 3 минуты
    await this.getItems()

    this.getNowIndicator()
  },
}
</script>

<style lang="scss">
.new-timetable {
  table {
    width: 100%;
    border-collapse: collapse !important;
    &.main-table {
      & > tbody {
        overflow: hidden;
      }
    }
    .table-header {
      position: sticky;
      top: 55px;
      background-color: #fff;
      z-index: 50;
      .header-cell {
        border: 1px solid #eee;
      }
    }

    .wrapper {
      position: relative;
    }
    .table-days {
      .inner {
        padding: 7px;
        text-align: center;
        span {
          display: inline-block;
        }
      }
    }
    .table-rooms {
      .rooms-inner {
        display: flex;
        flex-flow: row nowrap;
        .room {
          flex: 1;
          font-size: 0.8rem;
          text-align: center;
          padding: 7px 0;
          &:not(:last-child) {
            border-right: 1px solid #eee;
          }
        }
      }
      td {
        border-bottom: 3px solid #aaa !important;
      }
    }
    .table-body {
      .body-wrapper {
        min-height: 100%;
      }
      .table-slots {
        position: relative;
        td {
          position: relative;
          &::after {
            content: '';
            position: absolute;
            top: -88px;
            width: 2px;
            background-color: #999;
            height: Calc(100% - 88px);
            right: 0px;
            z-index: 100;
          }
        }
        .hour-cell {
          height: 1.9rem;
          padding-right: 5px;
          line-height: 1.9rem;
          text-align: right;
          border: 1px solid #eee;
          position: relative;
        }
        .hour-row:nth-child(2n-1) {
          border-top: 2px solid #eee;
        }
      }
      .table-cels {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        table {
          min-height: 100%;
        }
        .event-cell {
          position: relative;
          border: 1px solid #eee;
          &.last {
            &:after {
              content: '';
              position: absolute;
              top: -88px;
              right: -3px;
              height: calc(100% + 70px);
              width: 2px;
              background-color: #999;
              z-index: 100;
            }
            &:last-child {
              &:after {
                right: -1px;
              }
            }
          }
          .event-cell-wrap {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            min-height: 100%;
          }
          .event {
            &.cancelled {
              border: 1px solid var(--font-negative-color);
              text-decoration: line-through;
              opacity: 0.5;
              box-shadow: none;
              &:before {
                position: absolute;
                content: '';
                width: 0.8rem;
                height: 0.8rem;
                border-radius: 50%;
                // background-color: var(--font-negative-color);
                background-color: red;
                z-index: 1;
                right: 3px;
              }
            }
            &.testing {
              background-color: var(--testing-bcg-color);
            }
            &.active {
              outline-color: var(--font-primary-color);
              z-index: 10;
            }
            position: absolute;
            display: block;
            background-color: #eee;
            border-radius: 3px;
            width: 100%;
            font-size: 0.6rem;
            padding: 3px;
            box-shadow: 1px 1px 1px 0 #ccc;
            overflow: hidden;
            color: var(--font-color);
            z-index: 4;
            outline: 4px solid transparent;
            transition: 0.2s all;
            .title {
              font-weight: bold;
              font-size: 0.65rem;
            }
            .teachers {
              &__main,
              &__other {
                word-break: keep-all;
                white-space: nowrap;
                padding: 0 1px;
              }
              &__main {
                background-color: #eeeeee77;
                border-radius: 2px;
              }
            }
            .done {
              position: absolute;
              top: 0;
              right: 0;
              .vs-icon {
                font-size: 0.8rem;
              }
            }
            .is_online {
              position: absolute;
              bottom: 0;
              padding: 1px;
              background-color: #eeeeee77;
              border-radius: 3px;
              .vs-icon {
                font-size: 0.8rem;
              }
            }
            &.in-progress {
              opacity: 1;
              .is_online {
                animation: online_in_progress 2s alternate infinite;
              }
            }
          }
          .event-cell-now-indicator {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            .indicator {
              height: 1px;
              width: 100%;
              position: absolute;
              background-color: red;
              z-index: 4;
            }
          }
        }
      }
    }
  }
  .manage {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    height: 3rem;
    column-gap: 15px;
    margin-bottom: 1rem;
    &-filters {
      flex: 1;
    }
    .vs-button {
      float: none;
      //   display: inline-block;
    }
    &-timetable {
      display: flex;
    }
    &-filters {
      display: flex;
      column-gap: 5px;
      align-items: center;
    }
  }
}
@keyframes online_in_progress {
  from {
    color: #000;
  }
  to {
    color: var(--font-positive-color);
  }
}
</style>
