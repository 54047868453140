var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"filters-panel"},[(this.teachers.length)?_c('div',{staticClass:"field"},[_c('v-select',{attrs:{"label":"nickname","options":_vm.teachers,"reduce":function (item) { return item.id; },"autocomplete":"on","clearable":"","placeholder":"Select teacher"},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var nickname = ref.nickname;
var options = ref.options;
return [_c('vs-avatar',{attrs:{"src":options && options.avatar
              ? (_vm.proxy + "/static/files/" + (options.avatar))
              : undefined,"size":"small"}}),_vm._v(_vm._s(nickname)+" ")]}},{key:"selected-option",fn:function(ref){
              var nickname = ref.nickname;
              var options = ref.options;
return [_c('vs-avatar',{attrs:{"src":options && options.avatar
              ? (_vm.proxy + "/static/files/" + (options.avatar))
              : undefined,"size":"small"}}),_vm._v(" "+_vm._s(nickname)+" ")]}}],null,false,729182374),model:{value:(_vm.selectedTeacher),callback:function ($$v) {_vm.selectedTeacher=$$v},expression:"selectedTeacher"}})],1):_vm._e(),_c('div',{staticClass:"field"},[_c('v-select',{attrs:{"label":"name","options":_vm.groups,"reduce":function (item) { return item.id; },"autocomplete":"on","clearable":"","multiple":"","placeholder":"Select groups","closeOnSelect":false},scopedSlots:_vm._u([{key:"option",fn:function(ref){
              var color = ref.color;
              var name = ref.name;
return [_c('vs-avatar',{attrs:{"icon":"people","color":color}}),_vm._v(" "+_vm._s(name)+" ")]}},{key:"selected-option",fn:function(ref){
              var color = ref.color;
              var name = ref.name;
return [_c('vs-avatar',{attrs:{"icon":"people","color":color,"size":"20px"}}),_vm._v(" "+_vm._s(name)+" ")]}}]),model:{value:(_vm.selectedGroups),callback:function ($$v) {_vm.selectedGroups=$$v},expression:"selectedGroups"}})],1),_c('div',{staticClass:"field"},[_c('v-select',{attrs:{"label":"filterClass","options":_vm.groupClasses,"reduce":function (item) { return item.filterClass; },"autocomplete":"on","clearable":"","multiple":"","placeholder":"Select group class","closeOnSelect":false},scopedSlots:_vm._u([{key:"option",fn:function(ref){
              var viewClass = ref.viewClass;
return [_vm._v(" "+_vm._s(viewClass)+" ")]}},{key:"selected-option",fn:function(ref){
              var viewClass = ref.viewClass;
return [_vm._v(" "+_vm._s(viewClass)+" ")]}}]),model:{value:(_vm.selectedClasses),callback:function ($$v) {_vm.selectedClasses=$$v},expression:"selectedClasses"}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }